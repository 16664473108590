export const players = [
  'Sam Myers',
  'Steven Chan',
  'Cameron Walls',
  'Carlos Ortega',
  'Yukhi Yamashita',
  'James Lockwood',
  'Matthew Chinn',
  'Jason Tong',
  'Andrew Silva',
  'Quincy Zhao',
  'Dale Zelmon',
  'Wynn Padula',
  'Leland Bailey',
  'Michael Cross',
  'Brendan Wilson',
  'Dan Keating',
  'Max Bruk',
  'Hector Franco',
  'Mike Basta',
];
